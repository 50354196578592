
import React, { useState, createContext ,useContext, useEffect} from "react";

// Create a new context
export const AppContext = createContext();

// Create a context provider component
export const AppProvider = ({ children }) => {
 const [ appData, setAppData ] = useState(false)
  const [ userData, setUserData ] = useState()
  const [ oauthUser, setOauthUser ] = useState(null)
  const [ geoData, setGeoData ] = useState(false)
  const [ view, setView ] = useState('sign-in')
  const [ showPreview , setShowPreview ] = useState(false)
  const [ showItemEdit, setShowItemEdit ] = useState(false)
  const [ showItemView, setShowItemView ] = useState(false)
  const [ currentItem, setCurrentItem ] = useState(false)
  const [ items, setItems ] = useState(false)
  const [ currentPackage, setCurrentPackage ] = useState(false)
  const [ packages, setPackages ] = useState(false)
  const [ appTheme, setAppTheme ] = useState({})
  const [ actionError, setActionError ] = useState(null)

  const updateAppData = (newData) => {
    setAppData((prevState) => ({
      userData,
      oauthUser,
      geoData,
      appTheme,
      view,
      showPreview,
      showItemEdit,
      showItemView,
      currentItem,
      items,
      currentPackage,
      packages,
      actionError,
      ...userData,
      ...prevState,
      ...newData,   
    }));
  };

  useEffect(()=>{
   
  },[userData])

  return (
    <AppContext.Provider value={{ 
        appData, 
        updateAppData,
        appTheme, 
        setAppTheme,
        view, 
        setView,
        showPreview,
        setShowPreview,
        showItemEdit, 
        setShowItemEdit,
        showItemView,
        setShowItemView,
        userData, 
        setUserData,
        geoData,
        setGeoData,
        actionError, 
        setActionError,
        oauthUser, 
        setOauthUser,
        currentItem,
        setCurrentItem,
        items, 
        setItems,
        currentPackage, 
        setCurrentPackage, 
        packages, 
        setPackages,
        }}>
      {children}
    </AppContext.Provider>
  );
};
export const AppState = () => {
  return useContext(AppContext);
};