import React from 'react'

function EnvRoute(env) {
  // env = 'dev'
  if (env === 'dev'){ return ["http://localhost:5005/api","http://localhost:5005/api"]
  }else{ return ["https://content-er-api.onrender.com/api","https://content-er-api.onrender.com/api"] }

}

export default EnvRoute

