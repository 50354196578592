import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import SignIn from '../Auth/SignIn';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FileUploader from '../Misc/FileUploader/Container';
import * as API from '../../utils/APICalls'


const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function PackageManager() {
  const [ packages, setPackages ] = useState([])
  const [user, setUser] = useState({_id:'6530aec05227961edc05c9b4'})
  const fetchPackages = async () =>{
    try{
      const  data = await API.fetchPackages({_id:user?._id})
      if(data)
      {
        console.log(data)
        setPackages([...data])
      }
    }catch(err){console.log(err)}
  }

  useEffect (()=>{
    fetchPackages()
  },[ ])
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {/* <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Album layout
          </Typography>
        </Toolbar>
      </AppBar> */}
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
        {/* <FileUploader /> */}

          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Package Manager
            </Typography>
          
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {packages &&  packages?.map((card) => (
              <Grid item key={card._id} xs={12} sm={6} md={4}>
                <Card
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    border:'3px solid #000',
                    borderRadius: '12px',
                    boxShadow: '0px -5px 10px 5px rgba(0,0,0,0.1)'
                }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                        boxShadow: 'inset 0px 0px 5px 3px rgba(0,0,0,0.5)'

                    }}
                    image= {card.img}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    {card.title}
                    </Typography>
                    <Typography>
                      {card.description}
                    </Typography>
                    <Typography>
                      Sales: {card.downloads}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Edit</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    
    </ThemeProvider>
  );
}