import * as React from 'react';
import { useState , useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { AppProvider, AppContext, AppState } from '../../context/AppProvider';
import Stack from '@mui/material/Stack';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  overflow: 'hidden',
//   whitespace: 'normal',
  textWrap: 'balance',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const EditItem = (props) => {
  const { appData, 
        updateAppData, 
        view, setView , 
        showItemEdit, 
        setShowItemEdit } = AppState()

        // const handleOpen = () => {
        //   setShowItemEdit(true);
        // };
        // const handleClose = () => {
        //   setShowItemEdit(false);
        // };
function ChildModal() {
  const [open, setOpen] = useState(false);

  const handleChildOpen = () => {
    setOpen(true);
  };
  const handleChildClose = () => {
    setOpen(false);
  };
  return (
      <React.Fragment>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
            <Button onClick={handleChildOpen}>Confirm Changes</Button>
            <Button onClick={handleClose}>Cancel</Button>
         </Stack>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            >
        <Box sx={{ ...style, width: '60%', borderRadius:'12px'  }}>
          <h2 id="child-modal-title">confirm changes</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p>
          <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
          <Button onClick={handleChildClose}>Save Changes</Button>
          <Button onClick={handleChildClose}>Cancel</Button>
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

    const [ item, setItem ] = useState(props.item)
    const [open, setOpen] = useState(showItemEdit);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setShowItemEdit(false); 
    };
    
    useEffect (()=>{
    
        },[showItemEdit])

  return (
    <div>
        <Modal
            open={showItemEdit}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box sx={{ ...style, width: 400, borderRadius:'12px' }}>
                <h2 id="parent-modal-title">Edit Item</h2>
                {/* <p id="parent-modal-description"> */}
                <p style={{ display:'flex', 
                    flexDirection:'column',
                    justifyContent:'center',
                    listStyle:'none', textAlign: 'left',margin:'0'}}>
                   <div style={{ 
                        display:'flex', 
                        flexDirection:'column', 
                        justifyContent:'center',
                        alignContent:'center',
                         width: '100%'
                         }}>
                    <img src={item.img} style={{width:'200px', margin:'auto'}} />
                    <h2 style={{margin:'0 auto'}}>{item.title}</h2>
                   </div>
                    {Object.keys(item).map((k,i)=>{
                        if( k === 'img' || k === 'title' || k === '__v'){
                            return 
                        }else{
                        return(<span key={i}
                            style={{marginLeft:'0'}}
                            >
                               <strong>{k}: </strong> {item[k]}
                            </span>)
                        }
                    })}
                </p>
                {/* </p> */}
                <ChildModal />
            </Box>
        </Modal>
    </div>
  )
}

export default EditItem