import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../context/AppProvider";

import axios from 'axios'

import EnvRoute from '../middleware/envRoute';
const ENDPOINT = EnvRoute('prod'); 



// const APICalls =() =>{


/*--------------------
------Scan Calls-------
----------------------*/

/*----------Create------------*/
export const saveScan = async(obj) =>{
    try {
        // console.log('Save Scan', obj)
        // setLoading(true);
        const config = {
            headers: {
                "Content-type": "application/json",
                //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.post(
            `${ENDPOINT[0]}/scan/`,
            obj,
            config
            );
            // console.log('Scan save response: ',data)
            return data
            
        } catch (err) {console.log('[Scan Save Error]: ',err)}
    }  
    
    /*----------Read------------*/

export const fetchOwnerScans = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/scan/owner`,
          {owner: userID},
           config
       );
    //    console.log("APICalls scans: ",data)
        return data

       }catch(err){console.log('[FetchQRs Error]',err)}
   }


/*--------------------
------follow Calls-------
----------------------*/

/*----------Create------------*/
export const saveFollow = async(obj) =>{
    try {
        // console.log('Save follow', obj)
        // setLoading(true);
        const config = {
            headers: {
                "Content-type": "application/json",
                //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.post(
            `${ENDPOINT[0]}/follow/`,
            obj,
            config
            );
            // console.log('Scan follow response: ',data)
            return data
            
        } catch (err) {console.log('[Follow Save Error]: ',err)}
    }  
    
    /*----------Read------------*/

export const fetchOwnerFollows = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/follow/id`,
          {owner: userID},
           config
       );
    //    console.log("APICalls follows: ",data)
        return data

       }catch(err){console.log('[FetchFollows Error]',err)}
   }



/*--------------------
------vCard Calls-------
----------------------*/

/*----------READ------------*/

 export const fetchVCard  = async( persona,token) =>{
      console.log("[fetching vCard] for: ", persona)
      const config = {
          headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    if(persona){

        try{
            const data  = await axios.post(`${ENDPOINT[0]}/vCard/`,persona, config)
            // console.log("fetch persona vCard: ", data?.data)
            return data.data
            
        }catch(err){console.log('[Fetch vCard Error]',err)}
    }
}


/*--------------------
------User Calls-------
----------------------*/

/*----------CREATE------------*/
export const createUser = async (userObj) => {
    
    // console.log('{createUser] pre-data:',userObj);
    const { firstName ,lastName ,preferredName,email ,password ,confirmPassword ,phone,isOnline,pic,linkage }= userObj
   
  
    try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
      const { data } = await axios.post(
        `${ENDPOINT[0]}/user/reg`, userObj, config
      );
    //   console.log('[createUser] data:',data);
    //   localStorage.setItem("QauRaUser", JSON.stringify(data));
      return data
   
    } catch (err) {console.log('[Create User Error]: ',err)
    }
  };
/*----------READ------------*/
export const userLogin = async (userObj) => {
   

   const { email, password }= userObj
    
  
      if ( !email || !password ) {
        return 'missing credentials';
      }
   
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${ENDPOINT[0]}/user/login`,
        {
          email,
          password,
        },
        config
      );
      return data
  
      
      } catch (err) {
        console.log('[User Login Error]: ',err)
        return 'unauthorized'
      
    }
        
    }
/*----------UPDATE------------*/
export const updateUser = async(userObj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userObj?.token}`
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/user/update`,
                {
                  email: userObj?.email,
                    ...userObj,
                },
                config
                );
            return data
        }catch(err){console.log('[Update User Error]: ',err)}  
    }   

/*--------------------
------Item Calls-------
----------------------*/

/*----------CREATE------------*/
export const createItem = async(itemData) =>{
    try {
         console.log('Item Save Click', itemData)
        // setLoading(true);
        const config = {
            headers: {
            "Content-type": "application/json",
            //   Authorization: `Bearer ${user.token}`,
            },
        };
        const item ={}= {...itemData}
        const  {data}= await axios.post(
            `${ENDPOINT[0]}/item/create`,
          {...item},
            config
        );
        console.log('save response: ',data)
        return data
        
    } catch (err) {console.log('[CreateItem Error]: ',err)}
  }  
  
  /*----------READ------------*/
  export const fetchItem  = async(token, package_id) =>{
      console.log("[fetching Qrs]")
      const config = {
          headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    try{
        const data  = await axios.post(`${ENDPOINT[0]}/item/check`,{package_id}, config)
        // console.log("fetchItem: ", data?.data)
        return data
        
    }catch(err){console.log('[FetchItem Error]',err)}
}

  export const fetchItemByID  = async( _id,token) =>{
      console.log("[fetching Item by Id]")
      const config = {
          headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${token}`,
        },
    };
    try{
        const data  = await axios.post(`${ENDPOINT[0]}/item/`,{_id}, config)
        console.log("fetchItem By Id: ", data?.data)
        return data
        
    }catch(err){console.log('[FetchItem Error]',err)}
}

export const fetchItems = async ( userID ) =>{
    try{
            const config = {
           headers: {
           "Content-type": "application/json",
        //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.post(
           `${ENDPOINT[0]}/item/items`,
          {owner: userID},
           config
       );
    //    console.log("Items: ",data)
        return data

       }catch(err){console.log('[FetchItems Error]',err)}
   }

/*----------UPDATE------------*/
export const updateItemCode = async(obj) =>{
    
      try {
       const config = {
           headers: {
           "Content-type": "application/json",
           //   Authorization: `Bearer ${user.token}`,
           },
       };
       const { data } = await axios.put(
           `${ENDPOINT[0]}/item/update`,
          { _id: obj?._id,
           ...obj},
           config
       );
       console.log('UpdateItemCode response: ',data)
        return data
   }catch(err){console.log('[updateItemCode Error]: ',err)}
 }  
/*----------DESTROY------------*/
export const deleteQR = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.put(
                `${ENDPOINT[0]}/item/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deleteQRFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeleteQR Error]: ',err)}   
    }
    

/*--------------------
------Package Calls-------
----------------------*/
 
/*----------CREATE------------*/
export const createPackage = async (obj,userID,packages) =>{
     const untitledCount = () =>{
        let count = packages?.filter(a=>a?.name.includes('untitled')).length
        return count}
  try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
       

        const { data } = await axios.post(
            `${ENDPOINT[0]}/package/create`,
            {
                ...obj,
                owner: userID,
                name: obj?.name ? obj?.name:'untitled package: #'+untitledCount() 
            },
            config
            );
        return data

       }catch(err){console.log('[CreatePackage Error]: ',err)}   
   }

   
    /*----------READ------------*/
export const fetchPackage = async (package_id) => {
        console.log('Package_id: ', package_id)
        try{
            const config = {
                headers: {
                    "Content-type" : "application/json"
                }
            }
            const { data } = await axios.post(`${ENDPOINT[0]}/package/check`,{ _id: package_id },config)
            console.log("[Redirect Data]:", data)
            return data
            
        }catch(err){console.log("[Redirect Error]: Fetching Package")}

    }
export const fetchPackages = async(userObj) =>{
    console.log("[fetching Packages] for:", userObj._id)
    try{
                const id = userObj?._id
                     const config = {
                         headers: {
                             "Content-type" : "application/json",
                             // Authorization: `Bearer ${userObj?.token}`,
                         },
                     };
                const data  = await axios.post(`${ENDPOINT[0]}/package/id`,{_id: id}, config)
                // console.log("[PackageList] data: ", data?.data)
                return data?.data
                
            }catch(err){console.log('[fetchPackages]: ',err)}
            }

/*----------UPDATE------------*/
export const updatePackage = async (obj) =>{
   try{
        const config = {
            headers: {
            "Content-type": "application/json",
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/package/update`,
            {
                _id: obj._id,
                ...obj,
            },
            config
            );
            console.log('[updatePackages]: data', data)
        return data
            
    }catch(err){console.log('[UpdatePackage Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deletePackage = async (obj)=>{
      try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.post(
                `${ENDPOINT[0]}/package/delete`,
                {_id: obj?._id},
                config
                );
              console.log('[deletePackageFromDB] - response:', data)
            return data
            
        }catch(err){console.log('[DeletePackage Error]: ',err)}   
    }
/*--------------------
------Persona Calls-----
----------------------*/

/*----------CREATE------------*/

export const createPersona = async (obj,userID,personas) =>{
    
         const untitledCount = () =>{
            let count = personas.filter(p=>p.personaTitle.includes('untitled')).length
            return count
         }

    try{
           const config = {
               headers: {
               "Content-type": "application/json",
            },
        };
        //    if(!obj?.owner){
               console.log('[createPersona] How farss the data getting?🤔 :', obj)
        //    }
           const { data } = await axios.post(
               `${ENDPOINT[0]}/persona/create`,
               {
                   ...obj,
                   owner: userID,
                   personaTitle: obj?.personaTitle ? obj?.personaTitle:'untitled persona: #'+untitledCount() 
                },
                config
                );
                return data
                
            }catch(err){console.log('[CreatePersona Error]: ',err)}   
        }
        
/*----------READ------------*/

export const fetchPersona = async (id) =>{
            // console.log("[fetching Persona]")
            const config = {
                headers: {
                "Content-type": "application/json",
            // Authorization: `Bearer ${user?.token}`,
            },
        };
        try{
            const data  = await axios.post(`${ENDPOINT[0]}/persona/id`,{_id:id}, config)
            console.log("[FetchPersona] data: ", data.data)
            return data.data
        }catch(err){console.log('[fetchPersona Error]: ',err)}
    }

export const fetchPersonas  = async(userObj) =>{
        const config = {
            headers: {
            // Authorization: `Bearer ${userObj?.token}`,
            },
        };
        try{
            const data  = await axios.post(`${ENDPOINT[0]}/persona/personas`,{_id: userObj?._id}, config)
            // console.log("[fetchPersonas] data: ", data.data)
            return data.data

        }catch(err){console.log('[fetchPersonas Error]: ',err)}
        }


/*----------UPDATE------------*/
export const updatePersona = async (obj) =>{
         try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/persona/update`,
                {
                    _id: obj?._id,
                    ...obj,
                },
                config
                );
                console.log('[updatePersona]: ', data)
                return data

        }catch(err){console.log('[updatePersona Error]: ',err)}   
    }


/*----------DESTROY------------*/
export const deletePersona = async (obj)=>{
    try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            
            const { data } = await axios.put(
                `${ENDPOINT[0]}/persona/delete`,
                {
                    ...obj,
                    // owner: userData?._id,
                    _id: obj?._id
                },
                config
                );
              console.log('[deletePersonaFromDB] - response:', data)
               return data
        }catch(err){console.log('[DeletePersona Error]: ',err)}   
    }


/*--------------------
------Misc. Calls-------
----------------------*/

export const savePic = (pics) => {
    let pic =''
    if (pics === undefined) {
    
      return;
    } 
  
  if (pics.type === "image/jpeg" || pics.type === "image/png") {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "QauRa");
      data.append("cloud_name", "dzxzdsnha");
      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          pic = data?.url.toString();
          console.log("data?.url.toString() :",data?.url.toString());
          return pic
      
        })
        .catch((err) => {
          console.log(err);
       
        });
    } 
    // else {
    
    //   return;
    // }
  }
  
// }
// export default APICalls