import { useCallback, useState } from 'react'
// import { FileList } from './FileList.js'
import { TargetBox } from './TargetBox.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const FileUploader = () => {

  const [droppedFiles, setDroppedFiles] = useState([])
  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const files = item.files
        setDroppedFiles(files)
      }
    },
    [setDroppedFiles],
  )
  return (
    <>
     <DndProvider backend={HTML5Backend}>

      <TargetBox onDrop={handleFileDrop} />
      </DndProvider>
      {/* <FileList files={droppedFiles} /> */}
    </>
  )
}

export default FileUploader