import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

const style = {
    border: '4px dotted #6FD4FF',
    borderRadius: '12px',
    boxShadow: '0px 3px 10px 5px rgba(0,0,0,0.4)',
    height: '15rem',
    width: '15rem',
    padding: '2rem',
    textAlign: 'center',
    color: '#000',
    // color: '#6FD4FF',
    backgroundColor: 'rgba(255,255,255,0.8)',
    backdropFilter: 'blur(15px)',
    margin: 'auto',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: '1rem',

}
export const TargetBox = (props) => {
  const { onDrop } = props
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item)
        }
      },
      canDrop(item) {
        console.log('canDrop', item.files, item.items)
        return true
      },
      hover(item) {
        console.log('hover', item.files, item.items)
      },
      collect: (monitor) => {
        const item = monitor.getItem()
        if (item) {
          console.log('collect', item.files, item.items)
        }
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    [props],
  )
  const isActive = canDrop && isOver
  return (
    <div ref={drop} style={style}>
         <input
               
                // onChange={handleChange}
                // accept={accept}
                // className={classes.hidden}
                style={{display:'none'}}
                id="file-upload"
                type="file"
            />

            <label
                htmlFor="file-upload"
                // {...dragEvents}
                // className={clsx(classes.root, isDragOver && classes.onDragOver)}
            >

        <img src={'/assets/cloud-arrow-up-solid.svg'}
            style={{opacity:'0.5', height: '5rem'}}
            />
            <p>
              {isActive ? 'Release to drop' : 'Click or drag file here to create item!'}
            </p>
            </label>
            
    </div>
  )
}
