import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import SignIn from '../Auth/SignIn';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FileUploader from '../Misc/FileUploader/Container';
import * as API from '../../utils/APICalls'
import EditItem from './EditItemModal';
import PreviewModal from './PreviewModal';
import { AppProvider, AppContext, AppState } from '../../context/AppProvider';
import ViewItem from './ViewItemModal';
import { Avatar, Divider, Icon, ListItem, Skeleton } from '@mui/material';
import { List } from '@mui/material';
import AddButton from '../AddButton';


const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ItemManager() {
  const { appData, 
          updateAppData, 
          view, setView , 
          showPreview , 
          setShowPreview, 
          showItemEdit, 
          setShowItemEdit,
          showItemView, 
          setShowItemView 
        } = AppState()
  
  const [ items, setItems ] = useState([])
  const [ currentItem, setCurrentItem ] = useState()
  // const [ edit , setEdit ] = useState(false)
  // const [ showPreview , setShowPreview ] = useState(false)
  const [ showAdd , setShowAdd ] = useState(false)
  const [user, setUser] = useState({_id:'6530aec05227961edc05c9b4'})
 
  const default_icon = (obj) =>{
    if(!obj.img){

      if (obj.type ==='audio'){return '/assets/music-solid.svg'}
      if (obj.type ==='video'){return '/assets/video-solid.svg'}
      if (obj.type ==='image'){return '/assets/image.svg'}
      if (obj.type ==='doc'){return '/assets/document.svg'}
      if (obj.type ==='misc'){return '/assets/money-insert.svg'}
    }else{return obj.img}
  }
  const fetchItems = async () =>{
    try{
      const  data = await API.fetchItems(user?._id)
      if(data)
      {
        console.log(data)
        setItems([...data])
      }
    }catch(err){console.log(err)}
  }

  useEffect (()=>{
    fetchItems()
  },[ ])
  useEffect (()=>{
  
  },[ ])

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {/* <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Album layout
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Paper className='item-manager' elevation={3} 
      
        sx={{ px: 2, py:2,
          display:'flex', 
          flexDirection:'column',
          justifyContent:'center',
          backgroundImage: "url('https://res.cloudinary.com/dzxzdsnha/image/upload/v1699410512/ShowPic_01_gnsa9r.jpg')",
          backgroundAttachment:'fixed',
          backgroundRepeat: 'none',
          backdropFilter: 'blur(15px)',
          backdropFilter: 'opacity(0.5)',
        }}>
        {/* Hero unit */}
        {/* <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        > */}
        {showAdd &&<div className={showAdd? 'appear':'hidden'}>

           <FileUploader sx={{paddingBottom:2}} />
        </div>}
       {/* {showAdd && <FileUploader sx={{paddingBottom:2}} className={showAdd? 'appear':'hidden'}/>} */}

          {/* <Paper elevation={2} sx={{display:'flex', justifyContent:'center'}}> */}
            
        
          {/* </Paper> */}
        {/* </Box> */}
          {/* End hero unit */}
        <Paper sx={{ py: 8, 
          // bgcolor:'background.paper',
          boxShadow: '0px 3px 10px 5px rgba(0,0,0,0.6)',
          backgroundColor: 'rgba(255,255,255,0.8)',
          backdropFilter: 'blur(15px)',
          display:'flex', 
          justifyContent:'center',
          borderRadius:'12px', 
          overflow:'hidden',
           }} elevation={3}>
          
          <List spacing={6} sx={{
            minWidth:{
            xs: 250,
            sm: 250,
            md: 500,
            lg: 500,
            xl: 500,
          },
         
          }}>
            <Box sx={{
                display:'flex', 
                 flexDirection: {
                        xs:'column',
                        sm:'row',
                        md:'row',
                        lg:'row',
                        xl:'row'
                      },
                // flex:1,
                width:'100%',
                alignItems:'center', 
                justifyContent:'center',
                // border:'1px solid red',
                margin: 'auto',
                gap:2,
               }}

                onClick={(e)=>{
                e.preventDefault()
                setShowAdd(!showAdd)
              }}
                >

              <AddButton open={showAdd} />
            <Typography
              component="h2"
              // variant="h3"
              //  variant= {{
              //       sm:'h5',
              //       md:'h4',
              //       lg:'h3'
              //   }}
              align="center"
              color="text.primary"
              // gutterBottom
              sx={{
                fontSize: {
                    xs:'1.5rem',
                    sm:'1.5rem',
                    md:'2.5rem',
                    lg:'2.5rem',
                    xl:'2.5rem'
                }
              }}
            >
              Item Manager
            </Typography>

            </Box>
              {!items &&
                <>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </>
               } 
              {items && items?.map((card,i) => (
              // <Grid item  xs={12} sm={6} md={4} key={card}>
                  <ListItem
                    key={i}
                    sx={{ 
                      width: '100%',
                      // height: '1.75rem', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent:'space-between',
                      gap: 2,
                      felx:1,
                      // border:'3px solid #000',
                      // borderRadius: '12px',
                      // boxShadow: '0px 5px 10px 5px rgba(0,0,0,0.1)'
                    }}
                  >
                    <Box sx={{
                      display:'flex',
                      flex: 1, 
                      alignItems:'center', 
                      justifyContent:'space-between',
                        flexDirection: {
                        xs:'column',
                        sm:'column',
                        md:'column',
                        lg:'row',
                        xl:'row'
                      },
                      gap:2,
                     }}>

                    <Box sx={{
                      display:'flex',
                      flex: 1, 
                      alignItems:'center', 
                      justifyContent:'space-between',
                      gap: 2 ,
                         }}>
                      
                
                      <Box sx={{display: 'flex',
                        flexDirection:'row',
                        flex:1, 
                        alignItems:'center', 
                        justifyContent:'space-between',
                        gap: 2,
                        flexDirection: {
                        xs:'column',
                        sm:'column',
                        md:'column',
                        lg:'row',
                        xl:'row'
                      },
                      }}>
                          <Icon  sx={{
                            width:  {
                            xs:'4rem',
                            sm:'4rem',
                            md:'4rem',
                            lg:'1.5rem',
                            xl:'1.5rem', 
                          },
                        height: {
                            xs:'4rem',
                            sm:'4rem',
                            md:'4rem',
                            lg:'1.5rem',
                            xl:'1.5rem', 
                          }, 
                          backgroundImage: "url("+`${default_icon(card)}`+")",
                          backgroundSize:'cover',
                          backgroundRepeat: 'none',
                          cursor: 'pointer'
                        }}
                           onClick = {(e)=>{
                            setCurrentItem(card)
                            setShowPreview(!showPreview)}}
                            >
                          </Icon>

                        {/* <Typography gutterBottom variant="h5" component="h3"> */}
                        <Typography  component="p">
                          <strong>{card.title}</strong>
                        </Typography>
                        <Typography component="p">
                          Sales: {card.downloads}
                        </Typography>
                        {/* <Typography variant="p">
                          {card.description}
                        </Typography> */}
                      </Box>
                    </Box>
                      { showPreview && <PreviewModal open={showPreview} img={currentItem?.img}/> }
                    {/* </CardContent> */}
                    <Box sx={{
                         flexDirection: {
                        xs:'column',
                        sm:'column',
                        md:'row',
                        lg:'row',
                        xl:'row'
                      },
                    }}>

                    <CardActions sx={{ 
                      justifyContent: 'center' }}>
                    
                      <Button size="small" 
                       onClick={(e)=>{ 
                        e.preventDefault()
                        setCurrentItem(card)
                        setShowItemView(!showItemView)
                        }}
                      >View</Button>
                      <Button size="small"
                        onClick={(e)=>{ 
                          e.preventDefault()
                          setCurrentItem(card)
                          setShowItemEdit(!showItemEdit)
                          }}
                      >Edit</Button>
                
                    </CardActions>
                    {/* </Box> */}
                    </Box>
                    { showItemEdit && <EditItem  open={showItemEdit} item={currentItem}/> }
                    { showItemView && <ViewItem  open={showItemView} item={currentItem}/> }
                   {/* <Divider  /> */}
                  </Box>
                    <Divider style={{width:'100%'}} component={'li'} variant='middle'/>
                  </ListItem>

              // </Grid>
                  ))}
          </List>
        </Paper>
      </Paper>
    
    </ThemeProvider>
  );
}