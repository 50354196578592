
import React, { useState } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Auth from './Auth/Auth';
import ViewPackage from './ViewPackage'
import Dashboard from './Dashboard/Dashboard'

import EnvRoute from '../middleware/envRoute';
import axios from 'axios';
import Pricing from './Pricing';
import CheckOut from './Checkout/Checkout';

  const Home = () => {
    const [ auth , setAuth ] = useState(true)
    const navigate = useNavigate();
    // const { id } = useParams()
    // console.log('Home id:', id)

      const RouteProvider = () =>{
        
          return ( 
            <Routes>                    
                <Route path ="/" element ={<Auth />} exact/> 
                <Route path ="/pricing"  element ={<Pricing />}/>
                <Route path ="/checkout"  element ={<CheckOut />}/>
                <Route path ="/package/:id"  element ={<ViewPackage />}/>
                <Route path ="/dashboard/:view" element ={<Dashboard />}/>
            </Routes>
        )
    }
    const ProtectedRoute = ({ component: Component,...rest}) =>{
        // const auth = true
        return(
            <Route
            {...rest}
            render ={()=>auth?(
                <Component />
                ):
                (
                    navigate('/auth')
        )
      }
      
      />
      )
    }
    return (
      <div> 
        <RouteProvider />
    </div>
    )
  }
  
  export default Home
    
