import * as React from 'react';
import { useState , useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { AppProvider, AppContext, AppState } from '../../context/AppProvider';
import Stack from '@mui/material/Stack';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  overflow: 'hidden',
//   whitespace: 'normal',
  textWrap: 'balance',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ViewItem = (props) => {
  const { appData, 
        updateAppData, 
        view, setView , 
        showItemView, 
        setShowItemView } = AppState()
    const [ item, setItem ] = useState(props.item)
    const [open, setOpen] = useState(showItemView);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setShowItemView(false); 
    };
    
    useEffect (()=>{
    
        },[showItemView])

  return (
    // <div>
        <Modal
            open={showItemView}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box sx={{ ...style, width: 400, borderRadius:'12px'  }}>
                <h2 id="parent-modal-title">Item info</h2>
                {/* <p id="parent-modal-description"> */}
                <p style={{ display:'flex', 
                    flexDirection:'column',
                    justifyContent:'center',
                    listStyle:'none', textAlign: 'left',margin:'0'}}>
                   <div style={{ 
                        display:'flex', 
                        flexDirection:'column', 
                        justifyContent:'center',
                        alignContent:'center',
                         width: '100%'
                         }}>
                    <img src={item.img} style={{width:'200px', margin:'auto'}} />
                    <h2 style={{margin:'0 auto'}}>{item.title}</h2>
                   </div>
                    {Object.keys(item).map((k,i)=>{
                        if( k === 'img' || k === 'title' || k === '__v'){
                            return 
                        }else{
                        return(<span key={i}
                            style={{marginLeft:'0'}}
                            >
                               <strong>{k}: </strong> {item[k]}
                            </span>)
                        }
                    })}
                </p>
                {/* </p> */}
                 <Stack
                    sx={{ pt: 4 }}
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    >
                    <Button onClick={handleClose}>Close</Button>
                </Stack>
            </Box>
        </Modal>
    // </div>
  )
}

export default ViewItem