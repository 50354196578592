import * as React from 'react';
import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { AppProvider, AppContext, AppState } from '../../context/AppProvider';

const MenuItems = () => {
  const { appData, updateAppData, view, setView } = AppState()

  const mainListItems = (
    <React.Fragment>
      <ListItemButton onClick = {(e) =>{
        // e.preventDefault()
          setView('dashboard/main')
          // window.location.assign('/dashboard/main')
          }}>
        <ListItemIcon>
          {/* <DashboardIcon /> */}
           <img src='/assets/dashboard.svg'  width ={'25px'}/>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
  
      <ListItemButton onClick = {(e) =>{
        //  e.preventDefault()
        setView('dashboard/items')
        // window.location.assign('/dashboard/items')
      }}>

        <ListItemIcon>
          <SubscriptionsIcon />
        </ListItemIcon>
        <ListItemText primary="Items" />
      </ListItemButton>
      
      <ListItemButton onClick = {(e) =>{setView('packages')}}>
        <ListItemIcon>
          <img src='/assets/gift.svg'  width ={'25px'}/>
          {/* <AutoAwesomeMosaicIcon /> */}

        </ListItemIcon>
        <ListItemText primary="Packages" />
      </ListItemButton>
  
      <ListItemButton onClick = {(e) =>{setView('orders')}}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>
  
      <ListItemButton onClick = {(e) =>{setView('customers')}}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItemButton>
  
      <ListItemButton onClick = {(e) =>{setView('reports')}}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
  
      {/* <ListItemButton>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItemButton> */}
    </React.Fragment>
  );
  
  const secondaryListItems = (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Saved reports
      </ListSubheader>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Current month" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last quarter" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Year-end sale" />
      </ListItemButton>
    </React.Fragment>
  );
  return (
    <div>
      {mainListItems}
    </div>
  )
}

export default MenuItems