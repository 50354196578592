import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import { AppProvider, AppContext, AppState } from '../context/AppProvider';
import * as API from '../utils/APICalls'


const ViewPackage = (props) => {
    const { appData, updateAppData, view, setView, currentPackage, setCurrentPackage } = AppState()
    // const id = props?._id 
    const  id  = '6530bb1950444f4c2c74e526'
    // const { id } = useParams()
    // console.log('ViewPackage id:', id)
    const fetchPack = async () =>{
        const product = await API.fetchPackage(id)
        console.log('ViewPackage product:', product)
        setCurrentPackage(product)
    }
    useEffect(()=>{
        if(!currentPackage){
            fetchPack()
        }else{return}
    },[currentPackage])
   
    return (
    <>
        
        {currentPackage && 
         <Container sx={{ py: 8 }} maxWidth="md">

        <Grid item key={'product'+currentPackage?._id} xs={12} sm={6} md={4}>
                <Card
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    border:'3px solid #000',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 10px 5px rgba(0,0,0,0.1)',
                }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    boxShadow: 'inset 0px 0px 5px 3px rgba(0,0,0,0.5)'

                    }}
                    image= {currentPackage?.img}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    {currentPackage?.title}
                    </Typography>
                    <Typography>
                      {currentPackage?.description}
                    </Typography>
                    <Typography>
                      Sales: {currentPackage?.downloads}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Purchase</Button>
                  </CardActions>
                </Card>
              </Grid>
         </Container>
              }
              </>
  )
}

export default ViewPackage