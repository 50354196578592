import React from 'react'
import SignInSide from './SignInSide'

const Auth = () => {
  return (
    <>
      <SignInSide />  
    </>
  )
}

export default Auth