import { Box } from '@mui/material'
import React, { useEffect } from 'react'

const AddButton = (props) => {
    const open = props.open
    const style = {
        border: '3px dotted #6FD4FF',
        borderRadius: open?'25px':'5px',
        boxShadow: '0px 0px 5px 3px rgba(0,0,0,0.1)',
        height: '2rem',
        width: '2rem',
        padding: '.125rem',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#6FD4FF',
         backgroundColor: 'rgba(255,255,255,0.6)',
        backdropFilter: 'blur(5px)',
        
        // margin: 'auto',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        fontSize: '1.5rem',
        rotate: open?'45deg':'0deg'
    }
useEffect(()=>{

},[open])
  return (
    <div style={style}>
        <strong>
            {!open? '+': '+'}
        </strong>   
    </div>
  )
}

export default AddButton