import React, { useState } from 'react';
import { Button, InputLabel, TextField } from '@mui/material';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard'
import SignIn from './components/Auth/SignIn';
import SignInSide from './components/Auth/SignInSide';
import Auth from './components/Auth/Auth';
import ViewPackage from './components/ViewPackage'
import SignUp from './components/Auth/SignUp';
import Checkout from './components/Payment/CheckOut';
import FileUploader from './components/Misc/FileUploader/Container'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Routes, Route, useNavigate} from 'react-router-dom';
import { BrowserRouter as Router, Link } from 'react-router-dom'
import Home from './components/Home'
import EnvRoute from './middleware/envRoute';
import axios from 'axios';

function App() {

  
  return (
    <div className="App">
      {/* <SignIn /> */}
      {/* <SignInSide /> */}
      {/* <SignUp /> */}
      {/* <Dashboard /> */}
        {/* <FileUploader /> */}
      {/* <Checkout /> */}

       <Router>
        <Home />
      </Router> 
    </div>
  );
}

export default App;
